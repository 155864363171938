<template>
  <div>
    <div style="position: relative" class="uploader" :style="{ height: height, width: width }">
      <el-upload class="avatar-uploader" name="file" action="placeholder" :show-file-list="false"
        :on-success="handleAvatarSuccess" :disabled="disabled" :before-upload="beforeAvatarUpload"
        :http-request="uploadImage">
        <img v-if="imageUrl" id="avatar" :src="imageUrl" class="avatar" :style="{ height: height, width: width }" />
        <i v-else class="el-icon-plus avatar-uploader-icon"
          :style="{ height: height, width: width, lineHeight: lineHeight }"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: 'f-image-upload',
  props: {
    value: {
      required: true,
    },
    size: {
      type: Number,
      default: 50,
    },
    height: {
      type: String,
      default: '96px',
    },
    width: {
      type: String,
      default: '96px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    lineHeight() {
      return this.height;
    },
  },

  watch: {
    // 页面上从接口获取图片ID后才能给value赋值，这时需要监听这个属性的变化
    value: function (val) {
      this.imageUrl = val;
    }
  },

  data() {
    return {
      imageSize: this.size,
      imageUrl: this.value,
    };
  },

  methods: {
    beforeAvatarUpload(file) {
      let isValid = false;
      const fileTypes = ['image/jpeg', 'image/png'];
      const isOversize = file.size / 1024 / 1024 < this.imageSize;

      if (fileTypes.includes(file.type)) {
        isValid = true;
      }

      if (!isValid) {
        this.$message.error('上传图片只能是JPEG、JPG或PNG格式!');
        return;
      }

      if (!isOversize) {
        this.$message.error('上传图片大小不能超过 ' + this.imageSize + 'MB!');
        return;
      }

      return isValid && isOversize;
    },

    async uploadImage(req) {
      const { res } = await this.$http.get('/Common/SignatureInfo/SignatureInfo', {
        params: { fileName: req.file.name },
      });
      if (res.isSuccess) {
        const data = res.data;
        let fd = new FormData();
        fd.append('OSSAccessKeyId', data.ossAccessKeyId);
        fd.append('policy', data.policy);
        fd.append('signature', data.signature);
        fd.append('key', data.key);
        fd.append('file', req.file);
        this.$http.post(data.uploadUrl, fd).then(() => {
          this.imageUrl = data.fileUrl;
          this.$emit('input', this.imageUrl);
        })
      }
    },

    handleAvatarSuccess() { },
  },
};
</script>

<style lang="scss" scoped>
.upload-btn {
  background-color: #e0e0e0;
  height: 33px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  margin-bottom: 20px;

}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader{
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}


.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}

.avatar {
  display: block;
}
</style>
