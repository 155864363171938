<!-- 球员管理 -->
<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>球员管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button icon="el-icon-back" type="text" title="返回" class="back" @click="backPage()"></el-button>
        <div class="bottom_btn">
            <el-button class="addVenue" @click="addplayer">新增</el-button>
        </div>
        <div class="hideTable">
            <el-table style="background-color: rgb(202, 202, 202);" :data="playerData">
                <el-table-column label="序号" prop="playerId" align="center"></el-table-column>
                <el-table-column label="头像"  align="center">
                    <template slot-scope="scope">
                        <el-image :src="scope.row.playerPic" align="center" style="width:50px;height:50px"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="姓名" prop="playerName" align="center"></el-table-column>
                <el-table-column label="球衣号" prop="playerNumber" align="center"></el-table-column>
                <el-table-column label="球衣尺码" prop="shirtSize" align="center"></el-table-column>
                <el-table-column label="位置" prop="playPosition" align="center"></el-table-column>
                <el-table-column label="类型" prop="joinType" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.joinType == 0">自行入队</span>
                        <span v-if="scope.row.joinType == 1">编辑入队</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editPlayer(scope.row)">修改</el-button>
                        <!-- <el-button type="text">退队</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :visible.sync="dialogVisible" :title="title" width="50%" @close="closeDialog">
            <el-form ref="form" :model="form" label-width="80px" class="infoForm">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="头像">
                            <f-image-upload v-model="form.playerPic" style="margin-left: 0" :width="'100px'"
                                :height="'100px'"></f-image-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名">
                            <el-input v-model="form.playerName" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="球衣号">
                            <el-input v-model="form.playerNumber" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="位置">
                            <el-select v-model="form.playPosition" placeholder="请选择球员位置">
                                <el-option v-for="item in playPosition" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="球衣尺码">
                            <el-select v-model="form.shirtSize" placeholder="请选择球衣尺码">
                                <el-option v-for="item in shirtSize" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-form-item label="类型">
                    <el-select v-model="form.type" placeholder="请选择入队类型">
                        <el-option v-for="item in playerType" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="commitPlayer()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import FImageUpload from '@/common/components/Upload/f-image-upload.vue';
export default {
    components: { FImageUpload},
    data() {
        return {
            dialogVisible: false,
            matchList: [],
            matchInfo: {},
            playerData: [],
            Jersey: [{
                value: "红色",
                label: "红色"
            },
            {
                value: "橙色",
                label: "橙色"
            },
            {
                value: "黄色",
                label: "黄色"
            },
            {
                value: "绿色",
                label: "绿色"
            },
            {
                value: "蓝色",
                label: "蓝色"
            },
            {
                value: "黑色",
                label: "黑色"
            },
            {
                value: "紫色",
                label: "紫色"
            }],
            playerType: [
                {
                    value: "自行入队",
                    label: "自行入队"
                },
                {
                    value: "编辑入队",
                    label: "编辑入队"
                }
            ],
            playPosition:[ {
                    value: "守门员",
                    label: "守门员"
                },
                {
                    value: "中卫",
                    label: "中卫"
                },
                {
                    value: "边后卫",
                    label: "边后卫"
                },
                {
                    value: "后腰",
                    label: "后腰"
                },
                {
                    value: "中场",
                    label: "中场"
                },
                {
                    value: "前腰",
                    label: "前腰"
                },
                {
                    value: "边锋",
                    label: "边锋"
                },
                {
                    value: "前锋",
                    label: "前锋"
                },
                {
                    value: "自由人",
                    label: "自由人"
                },
                {
                    value: "清道夫",
                    label: "清道夫"
                },
                {
                    value: "领队",
                    label: "领队"
                },
                {
                    value: "裁判",
                    label: "裁判"
                },
                {
                    value: "教练",
                    label: "教练"
                }],
                shirtSize:[   {
                    value: "s",
                    label: "s"
                },
                {
                    value: "M",
                    label: "M"
                },
                {
                    value: "L",
                    label: "L"
                },
                {
                    value: "xl",
                    label: "xl"
                },
                {
                    value: "xxl",
                    label: "xxl"
                },
                {
                    value: "3xl",
                    label: "3xl"
                },
                {
                    value: "4xl",
                    label: "4xl"
                }],
            form: {},
            title: ''
        };
    },
    mounted() {
        this.getPlayerList()
    },
    methods: {
        // 获取球员
        async getPlayerList() {
            const { res } = await this.$http.get(`/Match/TeamPlayerList/${this.$route.query.teamId}`);
            this.playerData = res.data || [];
        },
        // 保存球员
        commitPlayer() {
            this.form.matchId = this.$route.query.matchId
            this.form.matchTeamId = this.$route.query.teamId
            this.$confirm('请核实信息，确定要添加该球员吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const { res } = await this.$http.post('/Match/SaveTeamPlayer', this.form);
                if (res.isSuccess) {
                    this.$showSuccess('发布成功');
                    this.dialogVisible = false
                    this.getPlayerList();
                }
            });
        },
        // 返回上一页
        backPage() {
            this.$router.back(-1)
        },
        closeDialog() {
            this.dialogVisible = false;
            this.title = '';
            this.form = {};
        },
        // 修改球员信息
        editPlayer(row) {
            this.dialogVisible = true
            this.title = "修改球员信息"
            this.form = this.cloneDeep(row);
        },
        // 新增球员
        addplayer() {
            this.dialogVisible = true,
                this.title = "新增球员"
        },
        // 深拷贝
        cloneDeep(target) {
            // 传入null，还是返回null
            if (target === null) {
                return target;
            }
            // 判断是否是对象
            if (typeof target === 'object') {
                // 兼容数组与对象
                let obj = Array.isArray(target) ? [] : {};
                for (let key in target) {
                    obj[key] = this.cloneDeep(target[key]);
                }
                return obj;
            } else {
                return target;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.back {
    margin-top: 10px;
    font-size: 18px;
}

.bottom_btn {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;

    .addVenue {
        background-color: #134f7a;
        color: #fff;
    }
}
</style>